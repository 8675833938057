import React, { useContext, useEffect, useState } from 'react';

import {
	Box,
	Link,
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableRow,
	Typography,
	Button,
	Grid,
} from '@mui/material';
import { ReactComponent as CloudUploadOutline } from '../../../svg/cloud-upload-outline.svg';
import { ReactComponent as CreateIcon } from '../../../svg/jam_write.svg';
import style from './insurance.module.scss';
import COIFileUpload from './upload-file';
import COIUploadDashBoard from './upload-dashboard';
import InsuranceForm from './insurance-form';
import { SessionContext } from '../../../context/SessionContext';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'src/utils/intercom';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { getComplianceIssues, respropNeeds } from 'src/utils/resprop-helper';
import api from 'src/api';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	//   '&:nth-of-type(even)': {
	'&[about="title"]': {
		background: '#F6F7FA',
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

interface InsuranceCompareProps {
	formatter: Intl.NumberFormat;
	coiValues: any;
	textStyle?: any;
	riskLevel: string;
	numEmployees: number;
	address: string;
}

function renderPolicyValue(
	formatter: Intl.NumberFormat,
	policyNum: string,
	expectedVal: number,
	value: number,
	styles: any,
	isCheck: boolean = true
) {
	if (((policyNum && value < expectedVal) || !policyNum) && isCheck) {
		return (
			<Typography sx={{ ...styles, color: '#E12D39' }}>{formatter.format(value ?? 0)} (insufficient Amount)</Typography>
		);
	}

	return <Typography sx={styles}>{formatter.format(value ?? 0)}</Typography>;
}

function isWrokersCom(riskLevel: string, numEmployees: number, address: string) {
	const addressComponents = address.split(',');
	const state = addressComponents.length > 2 ? addressComponents[addressComponents.length - 2] : '';
	if (riskLevel === 'HIGH' && numEmployees >= 4) {
		return true;
	} else if (riskLevel === 'HIGH') {
		return false;
	} else if (
		(riskLevel === 'LOW' && (state === 'FL' || state === 'SC') && numEmployees >= 4) ||
		(state === 'GA' && numEmployees >= 3) ||
		(state === 'AZ' && numEmployees >= 1) ||
		state === 'IL' ||
		state === 'OK' ||
		state === 'CO' ||
		state === 'UT'
	) {
		return true;
	} else if (riskLevel === 'LOW') {
		return false;
	} else if (riskLevel === 'NONE') {
		return false;
	}
	return false;
}

function renderInsuranceCompare({
	formatter,
	coiValues,
	textStyle = {},
	riskLevel,
	numEmployees,
	address,
}: InsuranceCompareProps) {
	return (
		<Grid container spacing={2}>
			<Grid item sm={6} xs={12}>
				<Box>
					<Typography variant="h5">Insurance requirements</Typography>
					<Box
						style={{
							borderRadius: '24px',
							background: '#BAC3E9',
							paddingTop: '8px',
							paddingBottom: '8px',
							paddingLeft: '24px',
							paddingRight: '24px',
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
							width: '170px',
						}}
					>
						<a
							href={
								riskLevel === 'HIGH'
									? 'https://www.respropmanagement.com/_files/ugd/2d056e_d38cf981373848d2bd1332efdba0a455.pdf'
									: 'https://www.respropmanagement.com/_files/ugd/2d056e_5fbfcd191f1e4ee495cd568bad1301db.pdf'
							}
							target={'_blank'}
						>
							<Typography
								variant="body2"
								sx={{
									color: '#0D1743',
									fontSize: '14px',
									lineHeight: '20px',
								}}
							>
								View Sample COI
							</Typography>
						</a>
					</Box>
				</Box>
				<Paper
					elevation={4}
					sx={{
						margin: '24px 0px !important',
					}}
				>
					<TableContainer
						sx={{
							maxHeight: '400px',
						}}
					>
						<Table
							sx={{
								maxWidth: '100%',
								borderRadius: '24px',
							}}
							aria-label="customized table"
						>
							<TableBody>
								<StyledTableRow about="title">
									<StyledTableCell component="th" scope="row">
										<Typography variant="h6">General Liability</Typography>
									</StyledTableCell>
									<StyledTableCell align="right"></StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="td" scope="row">
										<Typography>General Aggregate</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography>$1,000,000</Typography>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="td" scope="row">
										<Typography>Each Occurrence</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography>$1,000,000</Typography>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="td" scope="row">
										<Typography>Personal & Adv Injury</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography>$1,000,000</Typography>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="td" scope="row">
										<Typography>Products - Comp/Op Agg</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography>$1,000,000</Typography>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow about="title">
									<StyledTableCell component="th" scope="row">
										<Typography variant="h6">Commercial Auto</Typography>
									</StyledTableCell>
									<StyledTableCell align="right"></StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="th" scope="row">
										<Typography>Combined Single Limit (ea)</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography>{riskLevel === 'HIGH' ? '$1,000,000' : '$500,000'} or</Typography>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="th" scope="row">
										<Typography>Bodily Injury (per person)</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography>{riskLevel === 'HIGH' ? '$500,000' : '$100,000'}</Typography>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="th" scope="row">
										<Typography>Bodily Injury (per accident)</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography>{riskLevel === 'HIGH' ? '$500,000' : '$300,000'}</Typography>
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="th" scope="row">
										<Typography>Property Damage (per accident)</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography>{riskLevel === 'HIGH' ? '$500,000' : '$100,000'}</Typography>
									</StyledTableCell>
								</StyledTableRow>
								{isWrokersCom(riskLevel, numEmployees, address) && (
									<>
										<StyledTableRow about="title">
											<StyledTableCell component="th" scope="row">
												<Typography variant="h6">Workers Comp</Typography>
											</StyledTableCell>
											<StyledTableCell align="right"></StyledTableCell>
										</StyledTableRow>
										<StyledTableRow>
											<StyledTableCell component="th" scope="row">
												<Typography>Each Accident</Typography>
											</StyledTableCell>
											<StyledTableCell align="right">
												<Typography>$100,000</Typography>
											</StyledTableCell>
										</StyledTableRow>
										<StyledTableRow>
											<StyledTableCell component="th" scope="row">
												<Typography>Disease EA Employee</Typography>
											</StyledTableCell>
											<StyledTableCell align="right">
												<Typography>$100,000</Typography>
											</StyledTableCell>
										</StyledTableRow>
										<StyledTableRow>
											<StyledTableCell component="th" scope="row">
												<Typography>Disease Policy Limit</Typography>
											</StyledTableCell>
											<StyledTableCell align="right">
												<Typography>$500,000</Typography>
											</StyledTableCell>
										</StyledTableRow>
									</>
								)}
								{riskLevel === 'HIGH' && (
									<>
										<StyledTableRow about="title">
											<StyledTableCell component="th" scope="row">
												<Typography variant="h6">Excess Liability</Typography>
											</StyledTableCell>
											<StyledTableCell align="right"></StyledTableCell>
										</StyledTableRow>
										<StyledTableRow>
											<StyledTableCell component="th" scope="row">
												<Typography>Each Occurrence</Typography>
											</StyledTableCell>
											<StyledTableCell align="right">
												<Typography>$2,000,000</Typography>
											</StyledTableCell>
										</StyledTableRow>
										<StyledTableRow>
											<StyledTableCell component="th" scope="row">
												<Typography>Aggregate</Typography>
											</StyledTableCell>
											<StyledTableCell align="right">
												<Typography>$2,000,000</Typography>
											</StyledTableCell>
										</StyledTableRow>
									</>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Grid>
			<Grid item sm={6} xs={12}>
				<Box>
					<Typography variant="h5">Your Insurance Details</Typography>
				</Box>
				<Paper
					elevation={4}
					sx={{
						margin: '60px 0px !important',
					}}
				>
					<TableContainer
						sx={{
							maxHeight: '400px',
						}}
					>
						<Table
							sx={{
								maxWidth: '100%',
								borderRadius: '24px',
							}}
							aria-label="customized table"
						>
							<TableBody>
								{/* General Liability */}
								<StyledTableRow about="title">
									<StyledTableCell component="th" scope="row">
										<Typography variant="h6">General Liability</Typography>
									</StyledTableCell>
									<StyledTableCell align="right"></StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="td" scope="row">
										<Typography>General Aggregate</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										{renderPolicyValue(
											formatter,
											coiValues['cgl_policyNumber'],
											1000000,
											coiValues['cgl_genAggregate'],
											textStyle
										)}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="td" scope="row">
										<Typography>Each Occurrence</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										{renderPolicyValue(
											formatter,
											coiValues['cgl_policyNumber'],
											1000000,
											coiValues['cgl_eachOccurence'],
											textStyle
										)}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="td" scope="row">
										<Typography>Personal & Adv Injury</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										{renderPolicyValue(
											formatter,
											coiValues['cgl_policyNumber'],
											1000000,
											coiValues['cgl_personal&advInjury'],
											textStyle
										)}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="td" scope="row">
										<Typography>Products - Comp/Op Agg</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										{renderPolicyValue(
											formatter,
											coiValues['cgl_policyNumber'],
											1000000,
											coiValues['cgl_products/comp/opAgg'],
											textStyle
										)}
									</StyledTableCell>
								</StyledTableRow>
								{/* Commercial Auto */}
								<StyledTableRow about="title">
									<StyledTableCell component="th" scope="row">
										<Typography variant="h6">Commercial Auto</Typography>
									</StyledTableCell>
									<StyledTableCell align="right"></StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="th" scope="row">
										<Typography>Combined Single Limit (ea)</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										{renderPolicyValue(
											formatter,
											coiValues['ca_policyNumber'],
											riskLevel == 'HIGH' ? 1000000 : 500000,
											coiValues['ca_combinedSingleLimit'],
											textStyle,
											riskLevel == 'HIGH'
												? (coiValues['ca_BIperPerson'] ?? 0) < 500000 &&
														(coiValues['ca_BIperAccident'] ?? 0) < 500000 &&
														(coiValues['ca_PD'] ?? 0) < 500000
												: (coiValues['ca_BIperPerson'] ?? 0) < 100000 &&
														(coiValues['ca_BIperAccident'] ?? 0) < 300000 &&
														(coiValues['ca_PD'] ?? 0) < 100000
										)}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="th" scope="row">
										<Typography>Bodily Injury (per person)</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										{renderPolicyValue(
											formatter,
											coiValues['ca_policyNumber'],
											500000,
											coiValues['ca_BIperPerson'],
											textStyle,
											(coiValues['ca_combinedSingleLimit'] ?? 0) < (riskLevel == 'HIGH' ? 1000000 : 50000)
										)}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="th" scope="row">
										<Typography>Bodily Injury (per accident)</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										{renderPolicyValue(
											formatter,
											coiValues['ca_policyNumber'],
											500000,
											coiValues['ca_BIperAccident'],
											textStyle,
											(coiValues['ca_combinedSingleLimit'] ?? 0) < (riskLevel == 'HIGH' ? 1000000 : 50000)
										)}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component="th" scope="row">
										<Typography>Property Damage (per accident)</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										{renderPolicyValue(
											formatter,
											coiValues['ca_policyNumber'],
											500000,
											coiValues['ca_PD'],
											textStyle,
											(coiValues['ca_combinedSingleLimit']?? 0) < (riskLevel == 'HIGH' ? 1000000 : 50000)
										)}
									</StyledTableCell>
								</StyledTableRow>
								{/* Workers Comp */}
								{isWrokersCom(riskLevel, numEmployees, address) && (
									<>
										<StyledTableRow about="title">
											<StyledTableCell component="th" scope="row">
												<Typography variant="h6">Workers Comp</Typography>
											</StyledTableCell>
											<StyledTableCell align="right"></StyledTableCell>
										</StyledTableRow>
										<StyledTableRow>
											<StyledTableCell component="th" scope="row">
												<Typography>Each Accident</Typography>
											</StyledTableCell>
											<StyledTableCell align="right">
												{renderPolicyValue(
													formatter,
													coiValues['wc_policyNumber'],
													100000,
													coiValues['wc_eachAccident'],
													textStyle
												)}
											</StyledTableCell>
										</StyledTableRow>
										<StyledTableRow>
											<StyledTableCell component="th" scope="row">
												<Typography>Disease EA Employee</Typography>
											</StyledTableCell>
											<StyledTableCell align="right">
												{renderPolicyValue(
													formatter,
													coiValues['wc_policyNumber'],
													100000,
													coiValues['wc_diseaseEachEmployee'],
													textStyle
												)}
											</StyledTableCell>
										</StyledTableRow>
										<StyledTableRow>
											<StyledTableCell component="th" scope="row">
												<Typography>Disease Policy Limit</Typography>
											</StyledTableCell>
											<StyledTableCell align="right">
												{renderPolicyValue(
													formatter,
													coiValues['wc_policyNumber'],
													500000,
													coiValues['wc_diseasePolicyLimit'],
													textStyle
												)}
											</StyledTableCell>
										</StyledTableRow>
									</>
								)}
								{/* Excess Liability */}
								{riskLevel == 'HIGH' && (
									<>
										<StyledTableRow about="title">
											<StyledTableCell component="th" scope="row">
												<Typography variant="h6">Excess Liability</Typography>
											</StyledTableCell>
											<StyledTableCell align="right"></StyledTableCell>
										</StyledTableRow>
										<StyledTableRow>
											<StyledTableCell component="th" scope="row">
												<Typography>Each Occurrence</Typography>
											</StyledTableCell>
											<StyledTableCell align="right">
												{renderPolicyValue(
													formatter,
													coiValues['um_policyNumber'],
													2000000,
													coiValues['um_eachOccurence'],
													textStyle
												)}
											</StyledTableCell>
										</StyledTableRow>
										<StyledTableRow>
											<StyledTableCell component="th" scope="row">
												<Typography>Aggregate</Typography>
											</StyledTableCell>
											<StyledTableCell align="right">
												{renderPolicyValue(
													formatter,
													coiValues['um_policyNumber'],
													2000000,
													coiValues['um_aggregate'],
													textStyle
												)}
											</StyledTableCell>
										</StyledTableRow>
									</>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Grid>
		</Grid>
	);
}

interface EndorsementProps {
	coiValues: any;
	coiIssues: string[];
	riskLevel: string;
}

function renderEndorsement({ coiValues, coiIssues, riskLevel }: EndorsementProps) {
	return (
		<Box
			sx={{
				width: '100%',
			}}
		>
			<Typography variant="h5">Endorsements</Typography>
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
				}}
			>
				<TableContainer style={{ width: '100%' }}>
					<Table aria-label="customized table">
						<TableBody>
							<TableRow style={{ borderRadius: '8px' }}>
								<StyledTableCell component="th" scope="row">
									<Typography variant="subtitle2">Needed on Policy</Typography>
								</StyledTableCell>
								<StyledTableCell align="right">
									<Typography variant="subtitle2">Status</Typography>
								</StyledTableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell component="th" scope="row">
									<Typography variant="body2">All coverage must be primary and non-contributory</Typography>
								</StyledTableCell>
								<StyledTableCell align="right">
									<Typography variant="body2" fontWeight={600} color={'#479433'}>
										Completed
									</Typography>
								</StyledTableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell component="th" scope="row">
									<Typography variant="body2">
										Add ResProp Management as an Additional Insured on your General Liability policy{' '}
									</Typography>
								</StyledTableCell>
								<StyledTableCell align="right">
									<Typography
										variant="body2"
										color={coiValues['cgl_AI'] && coiValues['ca_AI'] ? '#479433' : '#E12D39'}
										fontWeight={600}
									>
										{coiValues['cgl_AI'] && coiValues['ca_AI'] ? 'Completed' : 'Incomplete'}
									</Typography>
								</StyledTableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell component="th" scope="row">
									<Typography variant="body2">Add ResProp Management as Certificate Holder </Typography>
								</StyledTableCell>
								<StyledTableCell align="right">
									<Typography
										variant="body2"
										color={
											coiValues['certificate_holder'] &&
											coiValues['certificate_holder'].indexOf(respropNeeds.certificate_holder) >= 0 &&
											coiValues['certificate_holder'].indexOf(respropNeeds.certificate_holder2) >= 0
												? '#479433'
												: '#E12D39'
										}
										fontWeight={600}
									>
										{coiValues['certificate_holder'] &&
										coiValues['certificate_holder'].indexOf(respropNeeds.certificate_holder) >= 0 &&
										coiValues['certificate_holder'].indexOf(respropNeeds.certificate_holder2) >= 0
											? 'Completed'
											: 'Incomplete'}
									</Typography>
								</StyledTableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell component="th" scope="row">
									<Typography variant="body2">Your policy needs to have Waiver of Subrogation Included</Typography>
								</StyledTableCell>
								<StyledTableCell align="right">
									<Typography variant="body2" color={coiValues['wc_wos'] ? '#479433' : '#E12D39'} fontWeight={600}>
										{coiValues['wc_wos'] ? 'Completed' : 'Incomplete'}
									</Typography>
								</StyledTableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell component="th" scope="row">
									<Typography variant="body2">Attach a copy of the 30 days notice of cancellation</Typography>
								</StyledTableCell>
								<StyledTableCell align="right">
									<Typography variant="body2" fontWeight={600} color={'#479433'}>
										Completed
									</Typography>
								</StyledTableCell>
							</TableRow>
							<TableRow>
								<StyledTableCell component="th" scope="row">
									<Typography variant="body2">Description of Operations</Typography>
								</StyledTableCell>
								<StyledTableCell align="right">
									<Typography
										variant="body2"
										fontWeight={600}
										color={
											coiIssues.indexOf('Description of operations should match sample COI') >= 0 ? '#E12D39' : '#479433'
										}
									>
										{coiIssues.indexOf('Description of operations should match sample COI') >= 0
											? 'Incomplete'
											: 'Completed'}
									</Typography>
								</StyledTableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Box>
	);
}

interface COIProps {
	enrollmentStatus?: string;
	coiChecks?: boolean;
}

const COILanding = ({ enrollmentStatus, coiChecks }: COIProps) => {
	const [tabIndex, setTabIndex] = useState<number>(0);
	const { session, riskLevel } = useContext(SessionContext);
	const { vendorId } = session.details;
	const [loading, setLoading] = useState<boolean>(false);

	const locationObj = useLocation();
	const query = new URLSearchParams(locationObj.search);

	const [coiValues, setCoiValues] = useState<any>({});
	const [coiValid, setCoiValid] = useState(false);
	const [coiIssues, setCoiIssues] = useState([] as any[]);
	const [vendor, setVendor] = useState({} as any);

	const { run } = useIntercom();

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 0,
	});

	const getVendor = async () => {
		let res: any = await api.getVendor(vendorId);
		setVendor(res.data);
	};

	//Set the corresponding tab active depending on what the user has filled
	useEffect(() => {
		if (vendorId) {
			getVendor();
		}
		if (enrollmentStatus && coiChecks) {
			const coiChecks = JSON.parse(sessionStorage.getItem('coiChecks')!!);
			if (coiChecks) {
				setCoiValues(coiChecks[0]);
				const complianceIssues = getComplianceIssues(coiChecks[0], riskLevel);
				setCoiIssues(complianceIssues);
				setCoiValid(complianceIssues.length === 0);
				setTabIndex(5);
			} else {
				setTabIndex(6);
			}
			// } else if (query.get('ip') && query.get('ip') === 'true') {
			// setTabIndex(3);
			// createConversationAndOpenChat();
		} else if (query.get('show') && query.get('show') === 'true') {
			const coiChecks = JSON.parse(sessionStorage.getItem('coiChecks')!!);
			setCoiValues(coiChecks[0]);
			const complianceIssues = getComplianceIssues(coiChecks[0], riskLevel);
			setCoiIssues(complianceIssues);
			setCoiValid(complianceIssues.length === 0);
			setCoiValid(coiValid);
			setTabIndex(4);
		} else {
			setTabIndex(1);
		}
	}, [vendorId]);

	useEffect(() => {
		if (tabIndex == 5 && sessionStorage.getItem('coiChecks')) {
			const coiChecks = JSON.parse(sessionStorage.getItem('coiChecks')!!);
			setCoiValues(coiChecks[0]);
			const complianceIssues = getComplianceIssues(coiChecks[0], riskLevel);
			setCoiIssues(complianceIssues);
			setCoiValid(complianceIssues.length === 0);
			setCoiValid(coiValid);
		}
	}, [tabIndex]);

	// const createConversationAndOpenChat = async () => {
	// 	const insureproUser = JSON.parse(sessionStorage.getItem('insurepro-user')!!);
	// 	const coiChecks = JSON.parse(sessionStorage.getItem('coiChecks')!!);
	// 	setCoiValues(coiChecks[0]);
	// 	if (!getMessage(coiChecks[0])) {
	// 		setCoiValid(true);
	// 		setTabIndex(4);
	// 	} else {
	// 		const payload = {
	// 			userId: insureproUser._id,
	// 			intercomId: insureproUser.intercomId,
	// 			message: getMessage(coiChecks[0]),
	// 			subject: 'test subject',
	// 			pdfUrl: coiChecks[0]['Public Access URL'],
	// 		};
	// 		await createNewConversationFromAdmin(payload);
	// 		openChat();
	// 	}
	// };

	// const openChat = () => {
	// 	setTabIndex(3);
	// 	run('insurepro');
	// };

	const selectTabIndex = (index: number) => {
		setTabIndex(index);
	};

	const expiredTextStyles: any =
		enrollmentStatus === 'EXPIRED' ? { textDecoration: 'line-through', textDecorationColor: 'red' } : {};
	return (
		<div>
			<TabPanel value={tabIndex} index={0}>{tabIndex === 0 && <>
				<Typography variant="h2">Certification of Insurance Information</Typography>
				<Typography mb={3} variant="body2">
					How do you want to enter your Insurance Information?
				</Typography>
				<Box mb={3}>
					<Paper onClick={() => selectTabIndex(1)} className={style.cursorPointer}>
						<Box p={5} flexDirection="column" display="flex" alignItems="center" justifyContent="center">
							<CloudUploadOutline />
							<Box mt={2}>
								<Typography sx={{ fontSize: 14 }} gutterBottom>
									Upload COI
								</Typography>
							</Box>
						</Box>
						{loading && (
							<Box p={8} display="flex" justifyContent="center">
								<CircularProgress />
							</Box>
						)}
					</Paper>
				</Box>
				<Box mb={8}>
					<Paper onClick={() => selectTabIndex(2)} className={style.cursorPointer}>
						<Box p={5} flexDirection="column" display="flex" alignItems="center" justifyContent="center">
							<CreateIcon />
							<Box mt={2}>
								<Typography sx={{ fontSize: 14 }} gutterBottom>
									Type it in myself
								</Typography>
							</Box>
						</Box>
						{loading && (
							<Box p={8} display="flex" justifyContent="center">
								<CircularProgress />
							</Box>
						)}
					</Paper>
				</Box>
				<Box>
					<Link href="https://www.investopedia.com/terms/c/certificate_of_insurance.asp" underline="none">
						What is a COI?
					</Link>
				</Box>
				</>}
			</TabPanel>
			<TabPanel value={tabIndex} index={1}>
				{tabIndex === 1 && <COIFileUpload tabChanger={setTabIndex} />}
			</TabPanel>
			<TabPanel value={tabIndex} index={2}>
				{tabIndex === 2 && <InsuranceForm tabChanger={setTabIndex} />}
			</TabPanel>
			<TabPanel value={tabIndex} index={3}>
				<></>
			</TabPanel>
			<TabPanel value={tabIndex} index={4}>
				{tabIndex === 4 && <div>
					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
							background: 'white',
							marginRight: '-16px',
							padding: '48px',
							borderRadius: '16px',
						}}
					>
						<Typography variant="h4">
							{coiValid ? 'Your insurance meet requirements' : 'Your insurance does not meet requirements'}
						</Typography>
						{!coiValid ? (
							<Typography variant="body1" paddingTop={1}>
								We found issues in your policy that makes you non-compliant in order to work for ResProp Management, Please
								review and resolve these issues.
							</Typography>
						) : (
							<></>
						)}
						{renderInsuranceCompare({
							formatter,
							coiValues,
							riskLevel,
							numEmployees: vendor?.bizInfo?.numEmployees || 0,
							address: vendor?.w9?.address || '',
						})}
						{renderEndorsement({ coiValues, coiIssues, riskLevel })}
						<Box
							sx={{
								width: '100%',
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'space-between',
								marginTop: '64px',
							}}
						>
							<Button
								style={{ background: 'rgba(186, 195, 233, 1)', color: 'rgba(13, 23, 67, 1)' }}
								onClick={() => {
									run('idcore', vendor.w9.businessName, vendor.bizInfo.email, vendor.referenceId);
								}}
							>
								Chat with Support
							</Button>
							{/* <Button style={{width: '48%'}} onClick>Upload new Documents</Button> */}
						</Box>
					</Box>
				</div>}
			</TabPanel>
			<TabPanel value={tabIndex} index={5}>
				{tabIndex === 5 && <div>
					{enrollmentStatus && enrollmentStatus !== 'COMPLIANT' && <Typography variant="h2" pb={2}>
						Certificate of Insurance Information
					</Typography>}
					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
							background: 'white',
							marginRight: '-16px',
							padding: '48px',
							borderRadius: '16px',
						}}
					>
						{enrollmentStatus && enrollmentStatus !== 'COMPLIANT' && (
							<Typography variant="h4">
								{coiValid ? 'Your insurance meet requirements' : 'Your insurance does not meet requirements'}
							</Typography>
						)}

						{enrollmentStatus && enrollmentStatus !== 'COMPLIANT' && !coiValid ? (
							<Typography variant="body1" paddingTop={1}>
								We found issues in your policy that makes you non-compliant in order to work for ResProp Management, Please
								review and resolve these issues.
							</Typography>
						) : (
							<></>
						)}
						{enrollmentStatus && enrollmentStatus !== 'COMPLIANT' ? renderInsuranceCompare({
							formatter,
							coiValues,
							textStyle: expiredTextStyles,
							riskLevel,
							numEmployees: vendor?.bizInfo?.numEmployees || 0,
							address: vendor?.w9?.address || '',
						}) : <></>}
						{enrollmentStatus && enrollmentStatus !== 'COMPLIANT' ? renderEndorsement({ coiValues, coiIssues, riskLevel }) : <></>}
						<Box
							sx={{
								width: '100%',
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'space-between',
								marginTop: '64px',
							}}
						>
							{enrollmentStatus && enrollmentStatus !== 'COMPLIANT' &&<Button
								style={{ background: 'rgba(186, 195, 233, 1)', color: 'rgba(13, 23, 67, 1)', width: '48%' }}
								onClick={() => {
									run('idcore', vendor.w9.businessName, vendor.bizInfo.email, vendor.referenceId);
								}}
							>
								Chat with Support
							</Button>}
							<Button style={{ width: '48%' }} onClick={() => setTabIndex(6)}>
								Upload new Documents
							</Button>
						</Box>
					</Box>
				</div>}
			</TabPanel>
			<TabPanel value={tabIndex} index={6}>
				{tabIndex === 6 && <COIUploadDashBoard tabChanger={setTabIndex} enrollmentStatus={enrollmentStatus} />}
			</TabPanel>
		</div>
	);
};

export default COILanding;
